$('document').ready(function() {

      let searchInput = $('#header_search'),
        searchInputPage = $('#searchPageInput'),
        searchPageBtn = $('#searchPageBtn'),
        searchBtn = $('#searchBtn');

      const isAdaptiveMode = () => {
        return ($(window).width() <= 1024);
      };

        $(`.search`).on(`click`, `.search__button`, function(evt) {
            const searchBlock = $(this).parent();

            if (isAdaptiveMode()) {
              searchBlock.parents(`.page-header`).addClass(`search-mob-open`);
            }

            searchBlock.parents(`.user-options__list`).addClass('search-open');
            searchBlock.addClass(`search--full`);
            searchBlock.find(`.search__input`).focus();
        });


      let checkSearchInput = function () {
        if(searchInput.val() !== '') {
          searchBtn.attr('type','submit');
        } else {
          searchBtn.attr('type','button');
        }
      };

      searchInput.on('input', checkSearchInput);
      
  searchInputPage.on('input', function () {
    if(searchInputPage.val() !== '') {
      searchPageBtn.attr('type','submit');
    } else {
      searchPageBtn.attr('type','button');
    }
  });

      $(`.close-search`).on(`click`, function () {
        $(`.page-header`).removeClass(`search-mob-open`);
        $(`.user-options__list`).removeClass('search-open');
        $(`.search`).removeClass(`search--full`);
      })

});


$(document).on(`click`, function (evt) {

    if (!$(evt.target).closest(`.search`).length && !$(evt.target).closest(`.close-search`).length) {
      $(`.page-header`).removeClass(`search-mob-open`);
      $(`.user-options__list`).removeClass('search-open');
      $(`.search`).removeClass(`search--full`);
    }

});

